import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/Seo"

import StandardPadding from "components/atoms/StandardPadding"

const NotFoundPage = ({ data }) => {
  const site = data.contentfulSite
  return (
    <Layout header={site.header}>
      <SEO siteTitle={site.title} title="404: Not Found" />
      <StandardPadding>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </StandardPadding>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    contentfulSite {
      title
      header {
        ... on ContentfulDefaultHeader {
          ...DefaultHeader
        }
      }
    }
  }
`
